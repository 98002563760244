/* Title styling */
.priceHead h2, .priceHead h1, .priceHead span {
    color: #fff;
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
}

.priceHead h2 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 600;
}

.priceHead h1 {
    font-size: 30px;
    font-weight: 700;
    margin: 0; /* Remove margin */
    line-height: 1.1; /* Adjust line-height to control space between elements */
}

.priceHead span {
    display: inline-block; /* Ensure span stays inline with h1 */
    margin-left: 5px; /* Add a slight margin to the left if needed */
    line-height: 1.1; /* Ensure the span aligns well with h1 */
    margin-bottom: 20px;
}



.centerText {
    text-align: center;
    margin-bottom: 20px;
}
.subHeading {
    font-size: 20px;
    font-weight: 500;
    color: #0039AD;
}
.heading {
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
}

/* Section styling */
.pricingContent {
    display: flex;
    justify-content: space-around; /* Center-align cards and add spacing */
    width: 100%;
    flex-wrap: wrap;
    gap: 20px; /* Spacing between cards */
    margin-top: 40px;
    margin-bottom: 40px;
}

/* Card styling */
.pricingDesign {
    flex: 1 1 calc(33.33% - 20px); /* Make each card one-third of the container width */
    max-width: 300px; /* Optional: max width for consistent sizing */
    margin: 2px;
    box-sizing: border-box;
}
.singlePricing {
    background: #0039AD;
    color: #fff;
    padding: 40px 20px;
    border-radius: 20px;
    box-shadow: 0 10px 20px -10px rgba(0, 64, 128, .2);
    text-align: center;
    position: relative;
    z-index: 1;
}
.singlePricing::before {
    content: "";
    background-color: #fff;
    opacity: 0.1;
    width: 100%;
    height: 100%;
    border-radius: 18px 18px 190px 18px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

/* Title styling */
/* .priceHead h2, .priceHead h1, .priceHead span {
    color: #fff;
}
.priceHead h2 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 600;
}
.priceHead h1 {
    font-size: 30px;
    font-weight: 700;
    margin: 20px 0;
} */

/* Button styling */
.priceBtn {
    background: #0aa1d6;
    color: #fff;
    padding: 10px 20px;
    display: inline-block;
    border-radius: 4px;
    margin-top: 15px;
    text-decoration: none;
    transition: background 0.3s;
}
.priceBtn:hover {
    background: #0039AD;
}

/* Section title styling */
.sectionTitle {
    text-align: center;
    margin-bottom: 40px;
}
.sectionTitle h2 {
    font-size: 36px;
    font-weight: 600;
    color: #0039AD;
}
